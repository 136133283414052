import { v4 as uuid4 } from 'uuid';
import { captureException } from '@sentry/react';

import Style from 'ol/style/Style';
import Fill from 'ol/style/Fill';
import Text from 'ol/style/Text';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import Point from 'ol/geom/Point';
import Feature from 'ol/Feature';
import MapBrowserEvent from 'ol/MapBrowserEvent';

import { fromLonLat, toLonLat } from 'ol/proj';
import DragPan from 'ol/interaction/DragPan';

import { LABEL_ACTIONS, LAYER_INDEX, MAP_LAYERS } from '../../../Constants/Constant';
import { adjustOverlayPosition, changeMapCursor } from '../../../Utils/HelperFunctions';
import { layerTracker, outputMap } from '../MapInit';
import { getHighlightTextStyle } from '../MapBase';
import { Observer } from '../../../Utils/Observer';
import { TOOL_EVENT } from '../../Output/Toolbar/ToolController';

class Labels extends Observer {
    activeCoords: $TSFixMe;

    domElements: $TSFixMe;

    hoveredFeature: $TSFixMe;

    invalidSpace: $TSFixMe;

    labelVisibility: $TSFixMe;

    labelsLayer: $TSFixMe;

    mapObj: $TSFixMe;

    requestId: $TSFixMe;

    selectedFeature: $TSFixMe;

    dragging: boolean;

    dragFeature: $TSFixMe;

    rotating: boolean;

    rotateFeature: Feature | null;

    startAngle: number;

    constructor(mapObj: $TSFixMe) {
        super();
        this.mapObj = mapObj;
        this.labelsLayer = null;
        this.domElements = null;
        this.activeCoords = [];
        this.selectedFeature = null;
        this.requestId = null;
        this.invalidSpace = false;
        this.dragging = false;
        this.dragFeature = null;
        this.rotating = false;
        this.rotateFeature = null;
        this.startAngle = 0;
    }

    on({ requestId }: $TSFixMe) {
        this.mapObj.map.on('pointermove', this.onMapHover);
        this.mapObj.map.on('singleclick', this.onMapClick);
        this.mapObj.map.on('dblclick', this.onMapDoubleClick);
        this.mapObj.map.on('pointerdown', this.onPointerDown);
        this.mapObj.map.on('pointerup', this.onPointerUp);

        this.requestId = requestId;
        this.activeCoords = [];
        this.domElements = {
            container: document.getElementById('label-container'),
            input: document.getElementById('label-input'),
            color: document.getElementById('label-color'),
            size: document.getElementById('label-size'),
            delete: document.getElementById('label-delete')
        };
    }

    // Helper function to handle overlay setup and positioning
    setOverlayPosition = (e: MapBrowserEvent<MouseEvent>, labelData: any = null) => {
        const { container, input, size, color, delete: deleteButton } = this.domElements;

        if (labelData) {
            // Double-click specific setup
            input.value = labelData.text;
            size.value = labelData.label_style.size;
            color.value = labelData.label_style.color;
            deleteButton.style.display = 'inline';
            this.activeCoords = [labelData.lon, labelData.lat];
        } else {
            // Single-click specific setup
            this.activeCoords = this.mapObj.isBlueprintMap ? e.coordinate : toLonLat(e.coordinate);
            deleteButton.style.display = 'none';
            size.value = 12;
        }

        container.style.display = 'block';
        const { offsetWidth: overlayWidth, offsetHeight: overlayHeight } = container || {};
        const { pageX, pageY } = e.originalEvent || {};

        const [positionX, positionY] = adjustOverlayPosition({
            pageX,
            pageY,
            overlayWidth,
            overlayHeight
        });

        //@ts-expect-error
        container.style.left = `${parseInt(positionX, 10)}px`;
        //@ts-expect-error
        container.style.top = `${parseInt(positionY, 10)}px`;
    };

    // Single click handler
    onMapClick = (e: MapBrowserEvent<MouseEvent>) => {
        this.selectedFeature = this.mapObj.map.forEachFeatureAtPixel(
            e.pixel,
            (_feature: $TSFixMe, _layer: $TSFixMe) => {
                if (_layer.get('id') === MAP_LAYERS.LABELS) {
                    return _feature;
                }
                return null;
            }
        );

        if (!this.selectedFeature) {
            this.setOverlayPosition(e);
        }
    };

    // Double click handler
    onMapDoubleClick = (e: MapBrowserEvent<MouseEvent>) => {
        this.selectedFeature = this.mapObj.map.forEachFeatureAtPixel(
            e.pixel,
            (_feature: $TSFixMe, _layer: $TSFixMe) => {
                if (_layer.get('id') === MAP_LAYERS.LABELS) {
                    return _feature;
                }
                return null;
            }
        );

        if (this.selectedFeature) {
            const labelData = this.selectedFeature.get('labelData');
            this.setOverlayPosition(e, labelData);
        }
    };

    // Attach both listeners to the map object

    onPointerDown = (e: MapBrowserEvent<MouseEvent>) => {
        // Check if cmd (mac) or ctrl (windows) is pressed
        const isRotateKey = e.originalEvent.altKey;
        if (isRotateKey) {
            this.mapObj.map.forEachFeatureAtPixel(e.pixel, (feature: $TSFixMe, layer: $TSFixMe) => {
                if (layer.get('id') === MAP_LAYERS.LABELS) {
                    if (isRotateKey) {
                        this.rotating = true;
                        this.rotateFeature = feature;
                        //@ts-expect-error
                        this.startAngle = this.calculateAngle(e.coordinate, feature); // Calculate initial angle
                    }
                    this.mapObj.map.getInteractions().forEach((interaction: $TSFixMe) => {
                        if (interaction instanceof DragPan) {
                            interaction.setActive(false);
                        }
                    });
                    return true;
                }
                return false;
            });
            return;
        }
        this.mapObj.map.forEachFeatureAtPixel(e.pixel, (feature: $TSFixMe, layer: $TSFixMe) => {
            if (layer.get('id') === MAP_LAYERS.LABELS) {
                this.dragging = true;
                this.dragFeature = feature;
                this.mapObj.map.getInteractions().forEach((interaction: $TSFixMe) => {
                    if (interaction instanceof DragPan) {
                        interaction.setActive(false);
                    }
                });
                return true;
            }
            return false;
        });
    };

    onPointerUp = () => {
        let labelData;
        if (this.dragging) {
            labelData = this.dragFeature.get('labelData');
        } else if (this.rotating) {
            //@ts-expect-error
            labelData = this.rotateFeature.get('labelData');
        }
        layerTracker.push(MAP_LAYERS.LABELS, labelData);
        this.notifyObservers(TOOL_EVENT.LABEL_UPDATED);
        this.dragging = false;
        this.rotating = false;
        this.dragFeature = null;
        this.rotateFeature = null;
        this.mapObj.map.getInteractions().forEach((interaction: $TSFixMe) => {
            if (interaction instanceof DragPan) {
                interaction.setActive(true);
            }
        });
    };

    addLabels(labels: $TSFixMe) {
        try {
            const prevLayer = this.mapObj.getLayerById(MAP_LAYERS.LABELS);
            if (prevLayer) this.mapObj.removeLayer(prevLayer);

            this.labelsLayer = new VectorLayer({
                source: new VectorSource({ wrapX: false }),
                // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to parameter of type 'Options'.
                id: MAP_LAYERS.LABELS,
                name: MAP_LAYERS.LABELS,
                zIndex: LAYER_INDEX.LABEL,
                layerData: { name: MAP_LAYERS.LABELS },
                style: this.getFeatureStyle
            });

            this.mapObj.addLayer(this.labelsLayer);

            if (labels.length) {
                for (let i = 0; i < labels.length; i++) {
                    this.addLabel(labels[i]);
                }
            }
        } catch (err) {
            captureException(err);
        }
    }

    onMapHover = (e: MapBrowserEvent<MouseEvent>) => {
        const isOptionPressed = e.originalEvent.altKey; // Check if Option (or Alt) key is pressed
        if ((this.dragging && this.dragFeature) || (this.rotating && this.rotateFeature)) {
            if (isOptionPressed) {
                this.rotateLabel(e);
            } else {
                this.dragLabel(e);
            }
            return;
        }

        // Cursor adjustment for invalid space
        changeMapCursor(this.invalidSpace, 'not-allowed', 'text');

        if (this.hoveredFeature) this.hoveredFeature.setStyle(null);
        this.mapObj.map.forEachFeatureAtPixel(e.pixel, (_feature: $TSFixMe, _layer: $TSFixMe) => {
            if (_layer.get('id') === MAP_LAYERS.LABELS) {
                this.hoveredFeature = _feature;
                const style = _layer.getStyle()?.(_feature);
                _feature.setStyle(getHighlightTextStyle(style));
                changeMapCursor(true, 'pointer');
                return true;
            }
            return false;
        });
    };

    addOrEditLabel() {
        if (this.selectedFeature) {
            this.editLabel();
        } else {
            this.addNewLabel();
        }
    }

    editLabel() {
        const labelData = { ...this.getLabelData(), id: this.selectedFeature.get('id'), action: LABEL_ACTIONS.EDITED };

        this.selectedFeature.set('labelData', labelData);
        this.hideContainer();
        this.selectedFeature = null;

        layerTracker.push(MAP_LAYERS.LABELS, labelData);
        this.notifyObservers(TOOL_EVENT.LABEL_UPDATED);
    }

    rotateLabel(e: MapBrowserEvent<MouseEvent>) {
        const [x1, y1] = this.rotateFeature.getGeometry().getCoordinates();
        const [x2, y2] = e.coordinate;
        const angle = -Math.atan2(y2 - y1, x2 - x1);

        const labelData = {
            ...this.rotateFeature.get('labelData'),
            label_style: {
                ...this.rotateFeature.get('labelData').label_style,
                rotation: angle
            },
            action: LABEL_ACTIONS.EDITED,
            id: this.rotateFeature.get('id')
        };
        this.rotateFeature.set('labelData', labelData);
        this.rotateFeature.setStyle(this.getFeatureStyle(this.rotateFeature));
    }

    dragLabel(e: MapBrowserEvent<MouseEvent>) {
        const coordinates = e.coordinate;
        this.dragFeature.getGeometry().setCoordinates(coordinates);
        const labelData = {
            ...this.dragFeature.get('labelData'),
            action: LABEL_ACTIONS.EDITED,
            id: this.dragFeature.get('id'),
            lon: toLonLat(coordinates)[0],
            lat: toLonLat(coordinates)[1]
        };
        this.dragFeature.set('labelData', labelData);
    }

    getRequestParams() {
        let params = {};
        if (this.mapObj.isBlueprintMap) {
            const worksheet_id = this.mapObj.baseLayer?.getProperties()?.bp_page_id;
            params = { worksheet_id };
        }
        return params;
    }

    getFeatureStyle(feature: $TSFixMe) {
        const labelData = feature.get('labelData');
        const font = `${labelData.label_style.size}px sans-serif`;
        const rotation = labelData.label_style.rotation || 0; // default to 0 if no rotation
        const style = new Style({
            text: new Text({
                font,
                text: labelData.text,
                fill: new Fill({ color: labelData.label_style.color || 'black' }),
                textAlign: 'left',
                rotation
            })
        });
        return style;
    }

    calculateAngle(coord: [number, number], feature: Feature): number {
        // @ts-expect-error TS(2339): Property 'getGeometry' does not exist on type 'never'.
        const center = feature?.getGeometry()?.getCoordinates();
        const dx = coord[0] - center[0];
        const dy = coord[1] - center[1];
        return Math.atan2(dy, dx);
    }

    getLabelData() {
        const labelData = {
            lon: this.activeCoords[0],
            lat: this.activeCoords[1],
            text: this.domElements.input.value,
            label_style: {
                size: this.domElements.size.value || 12,
                color: this.domElements.color.value
            }
        };
        return labelData;
    }

    addNewLabel() {
        const labelData = { ...this.getLabelData(), id: uuid4(), action: LABEL_ACTIONS.CREATED };

        this.addLabel(labelData);
        this.hideContainer();

        layerTracker.push(MAP_LAYERS.LABELS, labelData);
        this.notifyObservers(TOOL_EVENT.LABEL_UPDATED);
    }

    deleteLabel({ labelId = null, deleteFeatureTool = false } = {}) {
        if (!labelId) labelId = this.selectedFeature.get('id');

        if (this.selectedFeature) {
            this.labelsLayer.getSource().removeFeature(this.selectedFeature);
            this.selectedFeature = null;
            this.hideContainer();
        }

        if (!deleteFeatureTool) {
            layerTracker.push(MAP_LAYERS.LABELS, labelId);
            this.notifyObservers(TOOL_EVENT.LABEL_UPDATED, labelId);
        }
    }

    resetContainerValue() {
        this.domElements.input.value = '';
        this.domElements.size.value = '';
        this.domElements.color.value = '#000000';
        if (this.hoveredFeature) this.hoveredFeature.setStyle(null);
    }

    addLabel(labelData: $TSFixMe) {
        const coordinates = [labelData.lon, labelData.lat];
        const feature = new Feature({
            geometry: new Point(this.mapObj.isBlueprintMap ? coordinates : fromLonLat(coordinates)),
            id: labelData.id,
            labelData,
            isLabel: true
        });
        feature.setProperties({ layerId: labelData.id });
        this.labelsLayer.getSource().addFeature(feature);
    }

    hideContainer() {
        this.domElements.container.style.display = 'none';
        this.resetContainerValue();
    }

    setLabelVisibility(val: $TSFixMe) {
        this.labelVisibility = val;
        outputMap.setVisibility(MAP_LAYERS.LABELS, val);
    }

    off() {
        this.mapObj.map.un('pointermove', this.onMapHover);
        this.mapObj.map.un('singleclick', this.onMapClick);
        this.mapObj.map.un('pointerdown', this.onPointerDown);
        this.mapObj.map.un('pointerup', this.onPointerUp);
        const mapContainer = document.getElementById('map');
        if (mapContainer) {
            mapContainer.style.cursor = 'default';
        }
    }
}

export default Labels;
